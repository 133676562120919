<template>
  <div class="w-full my-5">
    <div class="product-card-history-promo-card mb-2 flex" v-bind:class="rootClassName">
      <img
        :alt="product_img_alt"
        :src="product_img_src"
        class="product-card-history-product-img h-28 w-28 flex-none object-cover"
      />
      <div class="product-card-history-container py-2 flex-grow flex flex-col align-start justify-between">
        <div class="product-card-history-text flex-none">
          {{product}}
        </div>
        <div class="grid grid-cols-3 gap-5 my-2">
          <div class="col-span-1" v-for="pv in object.product_variants" :key="pv.id">
            <span class="block mb-1 checkout-producttitle02 font-semibold">{{ pv.title }}</span>
            <span>{{ pv.name }}</span>
          </div>
        </div>
        <div class="product-card-history-container1 flex-none">
          {{ price }}
        </div>
      </div>
    </div>
    <button 
      v-if="review"
      @click="reviewProduct"
      class="border border-solid border-black px-4 py-3 font-semibold" 
      style="font-family: Montserrat;"
    >
      Review Product
    </button>
  </div>
</template>

<script>
export default {
  name: 'ProductCardHistory',
  props: {
    review: {
      type: Boolean,
      default: false
    },
    object: Object,
    product_img_src: {
      type: String,
      default: '/playground_assets/product1-1000w.png',
    },
    product_img_alt: {
      type: String,
      default: 'image',
    },
    rootClassName: String,
    price: {
      type: String,
      default: 'IDR 1.350.000',
    },
    product: {
      type: String,
      default: '',
    },
    orderId: {
      type: String,
      default: '',
    },
  },
    methods: {
      reviewProduct() {
        this.$store.commit('setProductReview', this.object);
        this.$router.push(`/product-review/${this.orderId}/${this.object.product_id}`);
      },
      goPage(page) {
        this.$router.push(page);
      },
  },
}
</script>

<style scoped>
.product-card-history-promo-card {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  z-index: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #FFFFFF;;
}
.product-card-history-product-img {
  width: 100px;
  object-fit: contain;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.product-card-history-container {
  height: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.product-card-history-text {
  color: #1F252C;
  font-size: 18px;
  font-style: normal;
  text-align: left;
  word-break: break-word;
  font-family: Lora;
  font-weight: 700;
}
.product-card-history-text1 {
  font-family: lora;
}
.product-card-history-container1 {
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #1F252C;

}
.product-card-history-text2 {
  color: #524538;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400;
}


</style>
