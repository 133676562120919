<template>
  <div>
    <div class="order-detail-container">
      <header-back
        text="Back To Order"
        rootClassName="rootClassName3"
      ></header-back>
      <div class="w-full max-xl:px-5">
        <div class="max-w-rifyo mx-auto">
          <div class="order-detail-container01">
            <h1 class="order-detail-text">Order Detail</h1>
            <div v-if="isFetching" class="py-5">
              <Loading />
            </div>
            <div v-else class="order-detail-item-order">
              <span class="order-detail-text01">{{
                formatDate(orderData.created_at)
              }}</span>
              <span class="order-detail-text02">
                <span class="order-detail-text03"></span>
                <span class="order-detail-text04">{{
                  orderData.invoice_number
                }}</span>
              </span>
              <div
                class="order-detail-container02"
                v-if="
                  orderData && orderData.product && orderData.product_indent
                "
              >
                <div
                  class="order-detail-container03 lg:border-r lg:border-gray-400 flex-none w-full lg:w-1/2"
                >
                  <div class="w-full flex flex-col flex-wrap space-y-5">
                    <div
                      class="order-detail-instock w-full"
                      v-if="orderData.product.length"
                    >
                      <span
                        class="order-detail-text05"
                        v-if="orderData.product.length"
                      >
                        <span class="text-lg leading-6 font-bold"
                          >Product In Stock</span
                        >
                      </span>
                      <product-card-history
                        :key="`product-${index}`"
                        :object="product"
                        :orderId="orderData.id"
                        :price="`IDR ${formatRupiah(product.price)}`"
                        :product="product.title"
                        :product_img_src="product.image"
                        :review="orderData.status === 'order_complete'"
                        :repayment="isRepay"
                        rootClassName="rootClassName"
                        v-for="(product, index) in orderData.product"
                      ></product-card-history>
                    </div>
                    <div
                      class="order-detail-indent w-full"
                      v-if="orderData.product_indent.length"
                    >
                      <span class="order-detail-text05">
                        <span class="text-lg leading-6 font-bold"
                          >Product Indent</span
                        >
                      </span>
                      <product-card-history
                        rootClassName="rootClassName"
                        v-for="(product, index) in orderData.product_indent"
                        :key="`indent-${index}`"
                        :object="product"
                        :orderId="orderData.id"
                        :price="`IDR ${formatRupiah(product.price)}`"
                        :product="product.title"
                        :product_img_src="product.image"
                        :review="orderData.status === 'order_complete'"
                        :repayment="isRepay"
                      ></product-card-history>
                      <router-link
                        :to="`/checkout-repayment/${orderData.id}`"
                        v-if="orderData.status == 'product_is_ready'"
                      >
                        <span
                          class="p-4 border border-black inline-block bg-transparent text-black text-lg leading-6 hover:bg-black hover:text-white cursor-pointer"
                          style="font-family: 'Montserrat'"
                        >
                          Checkout Repayment
                        </span>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="order-detail-container03 ml-8 flex-none w-full lg:w-1/2">
                  <span class="order-detail-text07">
                    <span class="text-lg leading-6 font-bold">Track Order</span>
                  </span>
                  <div v-if="trackOrder.length">
                    <div class="relative mb-8 flex flex-col gap-y-8">
                      <div
                        class="z-0 absolute w-0.5 h-full bg-white"
                        style="left: 7px"
                      ></div>
                      <div
                        class="flex align-center"
                        :key="`order-${index}`"
                        style="font-family: Montserrat"
                        v-for="(manifest, index) in trackOrder"
                      >
                        <div
                          class="z-10 mr-4 flex-none h-4 w-4 rounded-full"
                          :class="index == 0 ? 'bg-black' : 'bg-white'"
                        ></div>
                        <div class="flex-grow">
                          <div class="mb-2 text-xs font-medium text-semiblack">
                            {{ formatDate(manifest.created_at) }}
                          </div>
                          <div :class="index == 0 ? 'font-medium text-semiblack' : 'font-normal text-semiblack'">
                            {{ manifest.description }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="flex align-center"
                    style="font-family: Montserrat"
                  >
                    <div
                      class="z-10 mr-4 flex-none h-4 w-4 rounded-full bg-black"
                    ></div>
                    <div class="flex-grow">
                      <div class="mb-2 text-xs font-medium text-semiblack">
                        {{ formatDate(orderData.created_at) }}
                      </div>
                      <div class="font-medium">Pesanan sedang diproses.</div>
                    </div>
                  </div>
                  <div
                    v-if="
                      (orderData.link_transaction && orderData.status == 'unsuccess' && !orderData.link_repayment ) || orderData.status == 'new_order'
                    "
                    @click="continuePayment(orderData.token_midtrans)"
                  >
                    <span
                      class="p-4 border border-black inline-block bg-transparent text-black text-lg leading-6 hover:bg-black hover:text-white cursor-pointer"
                      style="font-family: 'Montserrat'"
                    >
                      Lanjutkan Pembayaran
                    </span>
                  </div>
                  <div
                    v-if="
                      (orderData.link_repayment && orderData.status == 'unsuccess')
                    "
                    @click="continuePayment(orderData.token_repayment)"
                  >
                    <span
                      class="p-4 border border-black inline-block bg-transparent text-black text-lg leading-6 hover:bg-black hover:text-white cursor-pointer"
                      style="font-family: 'Montserrat'"
                    >
                      Lanjutkan Pembayaran
                    </span>
                  </div>
                  <!-- <div v-if="orderData.status == 'order_complete'" class="order-detail-btn-details cursor-pointer" @click="goReview()">
                    <span class="order-detail-text21">
                      <span class="order-detail-text22">Give A Review</span>
                    </span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import HeaderBack from '../components/header-back';
  import ProductCardHistory from '../components/product-card-history';
  import Footer from '../components/footer';
  import Api from '../api';
  import moment from 'moment';
  import axios from 'axios';
  import Loading from '../components/Loading.vue';

  export default {
    name: 'OrderDetail',
    components: {
      HeaderBack,
      ProductCardHistory,
      Footer,
      Loading,
    },

    data() {
      return {
        orderData: {
          created_at: null,
        },
        orderRepayment: '',
        harga: 0,
        nama: null,
        photo: null,
        isFetching: false,
      };
    },

    mounted() {
      let snap = document.createElement('script')
      snap.setAttribute('src', 'https://app.sandbox.midtrans.com/snap/snap.js')
      snap.setAttribute('data-client-key', process.env.VUE_APP_MIDTRANS_KEY)
      snap.setAttribute('async', true)
      snap.setAttribute('deffer', true)
      document.head.appendChild(snap)
    },

    computed: {
      trackOrder() {
        if (this.orderData.tracking.length) {
          return this.orderData.tracking.reverse();
        } else {
          return [];
        }
      },
      trackLast() {
        if (this.orderData.tracking.length) {
          return this.orderData.tracking.slice(-1)[0];
        }
      },
    },

    async created() {
      await this.getOrderDetail();
      await this.getOrderDetailRepayment();
    },

    methods: {
      async getOrderDetail() {
        try {
          this.isFetching = true;
          const order = await axios.get(
            `${Api.orderDetailUrl}/${this.$route.params.id}/detail`
          );
          this.orderData = order.data.data;
          let pv = null;
          try {
            pv = JSON.parse(order.data.data.product[0].product_variants);
          } catch (error) {
            pv = JSON.parse(order.data.data.product_indent[0].product_variants);
          }
          this.photo = pv[0].image;
          try {
            this.nama = order.data.data.product[0].title;
          } catch (error) {
            this.nama = order.data.data.product_indent[0].title;
          }
          try {
            this.harga = order.data.data.product[0].price;
          } catch (error) {
            this.harga = order.data.data.product_indent[0].price;
          }
          for (let index = 0; index < this.orderData.product.length; index++) {
            let variants = JSON.parse(
              this.orderData.product[index].product_variants
            );
            this.orderData.product[index].product_variants = variants;
          }
          for (
            let index = 0;
            index < this.orderData.product_indent.length;
            index++
          ) {
            let variants = JSON.parse(
              this.orderData.product_indent[index].product_variants
            );
            this.orderData.product_indent[index].product_variants = variants;
          }
        } catch (error) {
          throw error;
        } finally {
          this.isFetching = false;
        }
      },
      async getOrderDetailRepayment() {
        const content = await axios.post(
            `${Api.orderDetailRepaymentUrl}/${this.$route.params.id}`
          );
        if (content.status === 200) {
          this.orderRepayment = content.data.data.midtrans.token;
        }
      },
      async continuePayment(token) {
        await this.payNow(token);
      },
      async continueRepayment(token) {
        await this.payNow(token);
      },
      async payNow(token) {
        await window.snap.pay(token);
      },
      firstImage(product) {
        let text = item?.image
        const myArray = text.split(",");
        return product?.image
          ? myArray[0]
          : 'https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png';
      },
      goPage(page) {
        this.$router.push(page);
      },
      goReview() {
        this.$router.push('/order-review/' + this.$route.params.id);
      },
      formatDate(date) {
        return date ? moment(date).format('D MMMM YYYY') : '';
      },
      formatRupiah(angka) {
        let ang = angka.toString();
        let number_string = ang.replace(/[^,\d]/g, '').toString(),
          split = number_string.split(','),
          sisa = split[0].length % 3,
          rupiah = split[0].substr(0, sisa),
          separator = '',
          ribuan = split[0].substr(sisa).match(/\d{3}/gi);
        if (ribuan) {
          separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return rupiah ? rupiah : '';
      },
    },
  };
</script>

<style scoped>
  .text-semiblack {
    color: #1f252c;
  }

  .order-detail-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: #eae3d9;
  }
  .order-detail-container01 {
    width: 100%;
    display: flex;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .order-detail-text {
    font-family: Lora;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #1f252c;
    margin-bottom: 32px;
  }
  .order-detail-item-order {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .order-detail-text01 {
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-halfunit);
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1f252c;
  }
  .order-detail-text02 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 34px;
  }
  .order-detail-text03 {
    font-family: Lora;
  }
  .order-detail-text04 {
    font-family: Lora;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #1f252c;
  }
  .order-detail-container02 {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .order-detail-container03 {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .order-detail-text05 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-unit);
    color: #1f252c;
  }
  .order-detail-text06 {
    font-family: Lora;
    color: #1f252c;
  }
  .order-detail-text07 {
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-unit);
    color: #1f252c;
  }
  .order-detail-text08 {
    font-family: Lora;
  }
  .order-detail-track {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .order-detail-container04 {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    display: flex;
    position: relative;
    align-items: flex-start;
    border-radius: 50%;
    flex-direction: column;
    background-color: #1f252c;
  }
  .order-detail-container05 {
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .order-detail-text09 {
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .order-detail-text10 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .order-detail-text11 {
    font-family: Lora;
  }
  .order-detail-track1 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .order-detail-container06 {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    display: flex;
    position: relative;
    align-items: flex-start;
    border-radius: 50%;
    flex-direction: column;
    background-color: #fff;
  }
  .order-detail-container07 {
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .order-detail-text12 {
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .order-detail-text13 {
    font-style: normal;
    font-family: Lora;
    font-weight: 400;
  }
  .order-detail-text14 {
    font-family: Lora;
  }
  .order-detail-track2 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .order-detail-container08 {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    display: flex;
    position: relative;
    align-items: flex-start;
    border-radius: 50%;
    flex-direction: column;
    background-color: #fff;
  }
  .order-detail-container09 {
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .order-detail-text15 {
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .order-detail-text16 {
    font-style: normal;
    font-family: Lora;
    font-weight: 400;
  }
  .order-detail-text17 {
    font-family: Lora;
  }
  .order-detail-track3 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .order-detail-container10 {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    display: flex;
    position: relative;
    align-items: flex-start;
    border-radius: 50%;
    flex-direction: column;
    background-color: #fff;
  }
  .order-detail-container11 {
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .order-detail-text18 {
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .order-detail-text19 {
    font-style: normal;
    font-family: Lora;
    font-weight: 400;
  }
  .order-detail-text20 {
    font-family: Lora;
  }
  .order-detail-btn-details {
    flex: 0 0 auto;
    width: 200px;
    border: 1px solid #1f252c;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .order-detail-text21 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 2.5px;
  }
  .order-detail-text22 {
    font-family: Montserrat;
  }
  .order-detail-container12 {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    border-left: 1px solid #aea79d;
    padding-left: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .order-detail-text23 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-unit);
  }
  .order-detail-text24 {
    font-family: Lora;
  }
  .order-detail-text25 {
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-unit);
  }
  .order-detail-text26 {
    font-family: Lora;
  }
  .order-detail-track4 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .order-detail-container13 {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    display: flex;
    position: relative;
    align-items: flex-start;
    border-radius: 50%;
    flex-direction: column;
    background-color: #1f252c;
  }
  .order-detail-container14 {
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .order-detail-text27 {
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .order-detail-text28 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .order-detail-text29 {
    font-family: Lora;
  }
  .order-detail-track5 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .order-detail-container15 {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    display: flex;
    position: relative;
    align-items: flex-start;
    border-radius: 50%;
    flex-direction: column;
    background-color: #fff;
  }
  .order-detail-container16 {
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .order-detail-text30 {
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .order-detail-text31 {
    font-style: normal;
    font-family: Lora;
    font-weight: 400;
  }
  .order-detail-text32 {
    font-family: Lora;
  }
  .order-detail-track6 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .order-detail-container17 {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    display: flex;
    position: relative;
    align-items: flex-start;
    border-radius: 50%;
    flex-direction: column;
    background-color: #fff;
  }
  .order-detail-container18 {
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .order-detail-text33 {
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .order-detail-text34 {
    font-style: normal;
    font-family: Lora;
    font-weight: 400;
  }
  .order-detail-text35 {
    font-family: Lora;
  }
  .order-detail-track7 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
  }
  .order-detail-container19 {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    display: flex;
    position: relative;
    align-items: flex-start;
    border-radius: 50%;
    flex-direction: column;
    background-color: #fff;
  }
  .order-detail-container20 {
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .order-detail-text36 {
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .order-detail-text37 {
    font-style: normal;
    font-family: Lora;
    font-weight: 400;
  }
  .order-detail-text38 {
    font-family: Lora;
  }
  .order-detail-btn-details1 {
    flex: 0 0 auto;
    width: 200px;
    border: 1px solid #1f252c;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .order-detail-text39 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 2.5px;
  }
  .order-detail-text40 {
    font-family: Montserrat;
  }
  @media (max-width: 991px) {
    .order-detail-container01 {
      width: 100%;
      margin-top: var(--dl-space-space-threeunits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .order-detail-item-order {
      padding-bottom: 32px;
    }
    .order-detail-container02 {
      flex: 1;
      width: 100%;
      flex-wrap: wrap;
    }
    .order-detail-container03 {
      flex: 100%;
      padding-right: 0px;
    }
    .order-detail-container12 {
      flex: 100%;
      border-top: 1px solid #fff;
      margin-top: var(--dl-space-space-oneandhalfunits);
      border-left: 0px;
      padding-top: var(--dl-space-space-oneandhalfunits);
      padding-left: 0px;
    }
    div#bullet::after {
      position: absolute;
      content: '';
      border: 2px solid #fff;
      height: 80px;
      left: 6px;
      z-index: 1;
      top: 16px;
    }
  }
</style>
